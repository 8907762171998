.breadcrumb {
    border-radius: 0;
    width: 98%;
    margin: 5px 1% 0;
    border: 0;
    background-color: transparent;
    padding: 5px 15px;
    font-size: 16px;

    a {
        color: $colorFern;
    }
}

.breadcrumb > li + li:before {
    content: '> ';
}
