/** Defined colors: **/
$colorGreen: #78c575;
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorBostonBlue: #428bca;
$colorMineShaft: #1f1f1f;
$colorMercury: #e4e4e4;
$colorDeYork: #78c575;
$colorTundora: #454545;
$colorGallery: #efefef;
$colorWhiteLinen: #f8f0e8;
$colorPromoted: #fbf5d7;
$colorFern: #5fad5c;
$colorJapaneseLaurel: #009a00;
$colorAlabaster: #f9f9f9;
