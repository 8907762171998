.bottom-links {
    background-color: $colorAlabaster;
    border: 0;
    border-radius: 10px;
    margin: 0 1% 10px;
    width: 98%;
    padding: 30px;
    font-size: 13px;

    a {
        margin: 0 10px;
        color: $colorTundora;
        border-right: 1px solid #BABCC1;
        padding-right: 23px;
        font-size: 16px;

        &:last-child {
            border-right: 0;
            padding-right: 0;
        }
    }

    @media (max-width: 767px) {
        display: none;
    }
}
