.offer-list {
    color: #3e3e44;

    ul {
        background-color: $colorAlabaster;
        border: 0;
        border-radius: 8px;
        float: left;
        list-style-type: none;
        margin: 0 1% 20px;
        padding: 17px 0 0;
        width: 98%;

        li {
            text-align: center;
            margin-bottom: 20px;

            i {
                background-color: $colorWhite;
                border: 1px solid $colorWhite;
                border-radius: 500px;
                color: $colorDeYork;
                display: inline-block;
                font-size: 60px;
                font-style: normal;
                font-weight: normal;
                height: 95px;
                line-height: 95px;
                margin-bottom: 5px;
                padding: 0;
                position: relative;
                text-align: center;
                width: 95px;
            }
        }
    }
}
