.react-select-container {
    .react-select {
        &__control {
            outline: 0;
            cursor: pointer;
            min-height: 34px;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
            border: 0;
        }

        &__control--is-focused {
            color: #333;
            background-color: #e6e6e6;
            border-color: #adadad !important;
        }

        &__option {
            padding: 5px 20px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.42857143;
            color: #555;
            white-space: nowrap;
            cursor: pointer;

            &--is-selected {
                color: #262626;
                text-decoration: none;
                background-color: #f3f3f3;
            }

            &--is-focused, &:hover {
                color: #262626;
                text-decoration: none;
                background-color: #f5f5f5;
            }
        }

        &__dropdown-indicator {
            padding: 6px;
        }

        &__menu {
            width: auto;
            min-width: 100%;
            margin-top: 0;
            z-index: 1000;
        }

        &__menu-notice {
            font-size: 13px;
            padding: 8px 8px;
        }
    }
}
