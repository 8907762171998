.navbar {
    border-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid $colorMercury;
    margin-bottom: 0;
    border-bottom: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
}

.navbar-default .container {
    border-radius: 0px 0px 10px 10px;
    margin-top: 0px;
}

.navbar-collapse {
    position: relative;
    bottom: -1px;
    float: right;
}

.nav > li > a {
    font-family: 'open_sansregular', Arial, sans-serif;
    text-transform: uppercase;
    padding: 30px 15px;
}

.navbar-default .navbar-nav > li > a {
    color: $colorMineShaft;
    transition: transform 0.3s ease;
}

.header .navbar-default .navbar-nav > li > a:hover,
.header .navbar-default .navbar-nav > li > a:focus {
    color: $colorJapaneseLaurel;
    transform: scale(1.2);
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent;
    color: $colorJapaneseLaurel;
    border-bottom: 3px solid $colorGreen;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    background-color:transparent;
    color: #555;
}

#logo {
    float: left;
    width: 284px;
    height: 73px;
    margin: 3px 0 4px 0;
    display: inline-block;
    background: url('/img/logo.svg') no-repeat;
    background-size: 100% 100%;
}

.top-add-button {
    margin: 23px 0 0 30px;

    @media (max-width: 1600px) {
        margin-top: 18px;
    }

    @media (max-width: 1200px) {
        margin-top: 13px;
    }

    @media (max-width: 900px) {
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        margin-left: 10px;
    }

    &:hover {
        opacity: 0.92;
    }

    .btn {
        background-color: $colorFern;
        color: $colorWhite;
    }
}

/** Sticky header **/
.is-sticky {
    border-top: 0 solid transparent;
    opacity: 1;

    .top-bar {
        display: none;
    }

    .navbar.navbar-default .container {
        border-radius: 0px 0px 10px 10px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    }
}

@media (max-width: 1600px) {
    #logo {
        width: 245px;
        height: 63px;
    }

    .header {
        .nav > li > a {
            padding: 25px 15px;
        }
    }
}

@media (max-width: 1200px) {
    #logo {
        width: 206px;
        height: 53px;
    }

    .header {
        .nav > li > a {
            padding: 20px 15px;
        }
    }

    // Use full width fot nav-tabs
    .nav-full .nav-tabs > li {
        display: table-cell;
        width: 1%;
    }
    .nav-full .nav-tabs {
        width: 100%;
    }
    .nav-full .nav-tabs > li {
        float: none;
    }
    .nav-full .nav-tabs > li > a {
        text-align: center;
    }
    .nav-full .nav-tabs > li {
        display: table-cell;
        width: 1%;
    }
}

@media (max-width: 960px) {
    .header {
        .nav > li > a {
            padding: 20px 10px;
        }
    }
}

@media (max-width: 900px) {
    #logo {
        width: 175px;
        height: 45px;
    }

    .header {
        .nav > li > a {
            padding: 17px 10px;
        }
    }
}

@media (max-width: 768px) {
    #logo {
        width: 195px;
        height: 50px;
        //width: 284px;
        //height: 73px;
        margin: 0 auto;
        float: none;
    }

    .header {
        .navbar-header {
            text-align: center;
        }

        .navbar-nav {
            float: none !important;
        }
    }


    .navbar-default .navbar-nav > li > a {
        transition: none;
    }

    .header .navbar-default .navbar-nav > li > a:hover,
    .header .navbar-default .navbar-nav > li > a:focus {
        transform: none;
    }
}

@media (max-width: 767px) {
    .header {
        .navbar-collapse {
            float: none;
        }
    }
}
