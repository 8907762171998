.top-bar {
    background-color: $colorGreen;
    height: 30px;
    line-height: 30px;
    font-family: 'open_sansregular', Arial, sans-serif;
    font-size: 13px;

    a {
        color: white;

        &:link {
            text-decoration: none;
        }
    }

    .container {
        background: none;
    }

    .login-link {
        cursor: default;
        margin-right: 10px;
        color: $colorWhite;
        display: none;

        &:after {
            content: '';
        }
    }

    .login-small-form {
        overflow: hidden;
        height: 30px;
        display:inline-block;

        .form-group {
            margin-bottom: 0;
            float: left;
            margin-left: 5px;

            .form-control {
                width: 120px;
                font-size: 13px;
                height: 26px;
                margin-top: 2px;
                padding: 0 6px;

                &:focus {
                    box-shadow: none;
                    border-color: #ccc;
                }
            }
        }

        .submit {
            margin-left: 5px;
            font-size: 13px;
            height: 26px;
            margin-top: 2px;
            padding: 0 6px;
        }
    }

    .logged-in {
        color: $colorWhite;

        strong {
            color: $colorGallery;
        }
        .btn-grey {
            margin-left: 5px;
            font-size: 13px;
            height: 26px;
            margin-top: 2px;
            padding: 0 6px;
            line-height: 25px;
        }
    }
}

@media (max-width: 767px) {
    .top-bar {
        .login-small-form {
            width: 0%;
            @include transition(width 1s ease-in-out);

            &.active {
                width: 70%;
            }

            .form-group {
                .form-control {
                    width: 60px;
                }
            }
        }

        .login-link {
            cursor: pointer;
            display: inline-block;
        }

        .login-link.active {
            &:after {
                content: ':';
            }
        }

        .header {
            .navbar-collapse {
                float: none;
            }
        }
    }
}
